import React from "react"
import loadable from "@loadable/component"
import { makeStyles } from "@material-ui/core/styles"
import "../../css/typography-muli.css"
const DemoPage = loadable(() => import("../../components/layouts/demo-page"))

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot

  return (
    <DemoPage
      name="Checker"
      pageTitle="Convaise - Ideenwettbewerb Hessen"
      // homepage="#"
      // token="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c"
      token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s" // bots-prod-01
      // token="AhaaisD-ce0.7csNC_FrUbij1tjiy_bqmh-hON-1H7nc0SA-L5RG4jE" // bot-process-dev-01
      botId="3pvf2h2qckz2pkzfx"
      pwProtected={true}
      avatar={false}
      avatarInitials={false}
      logo="https://static.convaise.com/convaise-logo.svg"
      domain="https://europe.directline.botframework.com/v3/directline"
      styleOptions={{
        primaryFont: "'Noto Sans', 'sans-serif'",
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#394d75",
      }}
      styleCustomizations={{
        "--button-blue": "#394d75",
        "--button-blue-light": "#394d75",
        "--line-height-normal": "1.5em",
        // "--button-orange": "rgba(255, 166, 0, 1)",
        // "--button-orange-light": "rgb(255, 182, 46)",
      }}
    />
  )
}

export default Chat
